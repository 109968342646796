.add-lonca-invoice-main-container {
    padding: 0 10px;
}

.add-lonca-invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 10px 1rem;
}

.add-lonca-invoice-button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.add-lonca-invoice-input-container {
    display: grid;
    grid-template-rows: repeat(auto-fill, 60px);
    grid-gap: 20px;
    padding: 1rem 1rem;

    .add-lonca-invoice-input-container-above {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 10px;
    }

    .add-lonca-invoice-input-container-below {
        display: grid;
        grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
        justify-content: start;
        align-items: start;
        grid-gap: 10px;

        .add-lonca-invoice-input-parasut-id {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            align-items: end;
        }
    }

    .add-lonca-invoice-form-item {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 0;

        .ant-form-item-label {
            font-weight: bold;
            font-size: 14px;
            text-align: left;
            margin-left: 5px;
        }
    }

    .add-lonca-invoice-form-item-checkbox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
        margin-bottom: 0;

        .ant-form-item-control {
            flex-grow: 0;
        }
    }
}

.add-lonca-invoice-products-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-lonca-invoice-form-item .ant-form-item-label > label {
    font-weight: bold;
    font-size: 14px;
}

.add-lonca-invoice-unmatched-warning {
    color: red;
    font-weight: bold;
}