.product-importer-container {
    width: 70%;
    position: absolute;
    top: 2%;
    left: 15%;
    background-color: rgb(238, 238, 238);
    background-color: white;;
    z-index: 1000;
    border: 1px solid grey;
    border-radius: 0.3rem;
    box-shadow: 0 0 100rem 100rem rgba(128, 128, 128, 0.5);

    .product-importer-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 2rem;
        border-bottom: 1px solid rgb(207, 207, 207);
        color: rgb(70, 70, 70);

        .product-importer-header-tabs {
            display: flex;
            flex-direction: row;

            .product-importer-header-title {
                padding: 0.8rem 1.2rem;
                margin: 0;
                font-size: 1.1rem;
                font-weight: 500;
                cursor: pointer;
            }

            .product-importer-active-header-title {
                color: green;
                border-bottom: 3px solid green;
            }
               
        }

        .product-importer-header-close {
            cursor: pointer;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    .product-importer-description {
        padding: 2rem 2rem;
        text-align: left;
        line-height: 1.2rem;
        font-weight: 500;
        color: rgb(70, 70, 70);

        .product-importer-description-text {
            margin: 0;
        }
    }

    .import-template-button-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: end;

        .import-template-button {
            border: none;
            text-decoration: underline;
            color: rgb(0, 81, 255);
            background-color: transparent;
            cursor: pointer;
            font-weight: 600;
            font-size: 0.8rem;
        }
    }

    .cautions-title {
        margin: 0.5rem 0;
        font-weight: 800;
        color: rgb(70, 70, 70);
    }

    .product-importer-allowed-fields-container {
        margin-top: 2rem;

        .product-importer-allowed-fields-title {
            font-weight: 800;
        }

        .product-importer-allowed-fields {
            margin: 0.7rem 0;
            display: flex;
            flex-direction: row;
            column-gap: 0.5rem;
            row-gap: 0.3rem;
            flex-wrap: wrap;

            .product-importer-allowed-field {
                padding: 0.2rem 0.5rem;
                font-size: 0.75rem;
                background-color: rgb(254, 226, 174);
                border-radius: 0.3rem;
                border: 1px solid rgb(229, 229, 229);
            }
        }

        .product-importer-allowed-fields-warning {
            margin-top: 1rem;
            background-color: rgb(255, 239, 61);
            padding: 0.5rem 0.7rem;
            border-radius: 0.3rem;
            font-size: 0.7rem;
            display: flex;
            flex-direction: column;
            gap: 0.1rem;
            box-shadow: 0 3px 4px 2px rgb(218, 210, 52);
            font-weight: 500;

            .product-importer-allowed-fields-warning-title {
                font-weight: 700;
                font-size: 0.85rem;
            }
        }
    }

    .product-importer-file-input {
        border: 1px solid rgb(229, 229, 229);
        border-radius: 0.3rem;
        margin: 0 2rem;
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        input {
            display: none;
        }

        .product-importer-file-input-button {
            border-radius: 0.3rem;
            border: 1px solid rgb(207, 207, 207);
            padding: 0.3rem 0.7rem;
            font-weight: 600;
            color: white;
            cursor: pointer;
            background-color: rgb(55, 159, 223);
            cursor: pointer;

            &:hover {
                background-color: rgb(43, 122, 172);
            }
        }

        .product-importer-file-input-button-disabled {
            background-color: rgb(207, 207, 207);
            cursor: not-allowed;

            &:hover {
                background-color: rgb(207, 207, 207);
            }
        }

        .product-importer-file-input-delete-button {
            cursor: pointer;
            color: red;
        }
    }

    .product-importer-immidiate-error {
        margin: 1rem 2rem 0 2rem;
        background-color: rgb(230, 90, 90);
        padding: 0.5rem 0.7rem;
        border-radius: 0.3rem;
        font-size: 0.7rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.1rem;
        box-shadow: 0 3px 4px 2px rgb(180, 70, 70);
        font-weight: 500;

        .product-importer-immidiate-error-title {
            font-weight: 700;
            font-size: 0.85rem;
        }
    }

    .product-importer-success-message {
        margin: 1rem 2rem 0 2rem;
        background-color: rgb(90, 230, 104);
        padding: 0.5rem 0.7rem;
        border-radius: 0.3rem;
        font-size: 0.7rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.1rem;
        box-shadow: 0 3px 4px 2px rgb(76, 194, 88);
        font-weight: 500;
        text-align: left;
        justify-content: flex-start;

        .product-importer-success-message-title {
            font-weight: 700;
            font-size: 0.85rem;
        }
    }

    .product-importer-buttons {
        padding: 1rem 2rem 1rem 2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 1rem;

        .product-importer-button {
            border-radius: 0.3rem;
            border: 1px solid rgb(207, 207, 207);
            padding: 0.5rem 1rem;
            font-weight: 600;
            color: white;
            cursor: pointer;
        }

        .product-importer-submit-button {
            background-color: rgb(33, 164, 33);

            &:hover {
                background-color: rgb(10, 129, 10);
            }
        }

        .product-importer-cancel-button {
            background-color: rgb(213, 213, 213);
            color: rgb(70, 70, 70);

            &:hover {
                background-color: rgb(193, 193, 193);
            }
        }

        .product-importer-button-disabled {
            background-color: rgb(207, 207, 207);
            cursor: not-allowed;

            &:hover {
                background-color: rgb(207, 207, 207);
            }
        }
    }
}