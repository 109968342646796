.invoice-products-main-container {
    border: 1px solid lightgray;
    padding: 1rem 1rem;
}

.invoice-products-header {
    display: grid;
    grid-template-areas: 
        "title title number"
        "filter filter filter";
    gap: 20px;
    align-items: center;
    margin-bottom: 1rem;

    .invoice-products-title {
        grid-area: title;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: left;
    }

    .invoice-products-number-of-products {
        grid-area: number;
        justify-self: flex-end;
    }
}

.invoice-products-header-filter-container {
    grid-area: filter;
    width: 100%;

    .invoice-products-header-filter-container-inner {
        width: 100%;
        display: flex;
        gap: 20px;
        text-align: left;
        align-items: flex-start;

        .invoice-products-filter-label {
            font-size: 14px;
            font-weight: 600;
            padding-left: 5px;
        }

        .invoice-products-filter-input {
            width: 100%;
            max-width: 250px;
            display: flex;
            flex-direction: column;
        }

        .invoice-products-filter-input .ant-picker {
            min-width: 235px;
        }

        .invoice-products-filter-select {
            width: 100%;
            max-width: 150px;
            display: flex;
            flex-direction: column;
        }
    }
}

.invoice-products-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-height: 500px;
    overflow-y: auto;
}

.invoice-product-card {
    position: relative;
    margin-bottom: 20px;
    width: 100%;

    .invoice-product-card-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 9fr;
        align-items: center;
        gap: 10px;
        margin-top: 2rem;

        img {
            width: 115px;
            height: auto;
            object-fit: cover;
            margin: 10px auto;
            border: 0.2px solid lightgray;
            border-radius: 1rem;
        }
    }
}

.invoice-product-card-button {
    position: absolute;
    right: 5px;
    top: 5px;
}

.invoice-product-card .ant-descriptions-item-content {
    font-size: 12px;
    padding: 2px 4px;
}
.invoice-product-card .ant-descriptions-item-label {
    font-size: 14px;
    padding: 4px 8px;
    font-weight: 600;
}

.invoice-product-card .ant-card-head-wrapper {
    padding: 0;
}

.invoice-product-card .ant-btn-secondary {
    background-color: darkred;
    color: white;
}
